<template>
    <section class="c-about-clients" data-scroll-section>
        <div class="o-container || o-padding -padding-big-top">
            <div class="c-about-layout || o-grid -col-12 -gutter">
                <div class="c-about-layout_col || u-gc-1/13 u-gc-2/5@from-small u-gc-3/6@from-large">
                    <h2 v-if="content.title" class="c-text-about -subtitle" v-html="content.title" />
                </div>
                <div class="c-about-layout_col || u-gc-1/13 u-gc-5/12@from-small u-gc-6/12@from-large">
                    <ul class="c-about-clients_list">
                        <li class="c-about-clients_item" v-for="(item, itemIndex) in items" :key="itemIndex">
                            <p class="u-screen-reader-text">{{ item.name }}</p>
                            <div v-if="imageSrcs[itemIndex]" class="c-figure">
                                <div class="c-figure_inner || o-lazy -no-background">
                                    <img 
                                        class="c-figure_image || o-lazy_image"
                                        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 205'%3E%3C/svg%3E"
                                        :data-src="imageSrcs[itemIndex]" 
                                        alt="tmp"
                                        data-scroll
                                        data-scroll-call="lazyLoad"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getContextImageUrl } from "../../core/images/contextImage";

export default {
    name: "AboutClients",
    props: {
        content: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            items: [],
            imageSrcs: []
        };
    },
    created() {
        this.items = this.content.list

        this.items.forEach((item) => {
            if (item.image) {
                this.imageSrcs.push(getContextImageUrl(item.image, 'aboutClients'))
            } else {
                this.imageSrcs.push('')
            }
        })
    }
};
</script>