<template>
    <section class="c-about-introduction" data-scroll-section>
        <div class="o-container || o-padding -padding-big-top">
            <div class="c-about-layout || o-grid -col-12 -gutter">
                <div class="c-about-layout_col || u-gc-1/13 u-gc-2/5@from-small u-gc-3/6@from-large">
                    <h2 v-if="content.title" class="c-text-about -subtitle" v-html="content.title" />
                </div>
                <div class="c-about-layout_col || u-gc-1/13 u-gc-5/12@from-small u-gc-6/12@from-large">
                    <p v-if="content.text" class="c-about-introduction_description || c-text-about -description" v-html="content.text" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AboutIntroduction",
    props: {
        content: {
            type: Object,
            default: () => {}
        }
    }
};
</script>