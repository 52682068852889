<template>
    <section class="c-about-contact" data-scroll-section>
        <div class="o-container || o-padding -padding-big-top">
            <div class="c-about-layout || o-grid -col-12 -gutter">
                <div class="c-about-layout_col || u-gc-1/13 u-gc-2/5@from-small u-gc-3/6@from-large">
                    <h2 v-if="content.title" class="c-text-about -subtitle" v-html="content.title" />
                </div>
                <div class="c-about-layout_col || u-gc-1/13 u-gc-5/12@from-small u-gc-6/12@from-large">
                    <ul class="c-about-contact_list">
                        <li v-if="mailTo" class="c-about-contact_item">
                            <a class="c-link -access" :href="mailTo" rel='noopener noreferrer' data-cursor="hover">
                                <span class="c-link_label">Email</span>
                            </a>
                        </li>
                        <li class="c-about-contact_item" v-for="(social, socialIndex) in socials" :key="socialIndex">
                            <a class="c-link -access" :href="social.url" rel='noopener noreferrer' data-cursor="hover">
                                <span class="c-link_label">{{ social.name }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AboutContact",
    props: {
        content: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            socials: [],
            mailTo: ''
        };
    },
    created() {
        if (this.content && this.content.list.length) {
            this.socials = this.content.list
        }

        if (this.content.email) {
            this.mailTo = `mailto:${this.content.email}`
        }
    }
};
</script>